import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'src/assets/css/reset.css';
import React from 'react';

import { enableES5 } from 'immer';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import App from 'src/App';
import store from 'src/redux/store';
import * as serviceWorker from 'src/serviceWorker';

import ErrorBoundary from './views/Errors/ErrorBoundary';

enableES5();

ReactDOM.render(
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

serviceWorker.unregister();
