import React from 'react';
import type { FC } from 'react';

import { Box, CircularProgress } from '@material-ui/core';

import { useStyles } from './Loading.style';
import { LoadingType } from './types';

const Loading: FC<LoadingType> = ({ variant = 'middle', ...rest }) => {
  const classes = useStyles();
  const className = variant === 'inline' ? classes.inline : classes.middle;
  return (
    <div className={className}>
      <Box>
        <CircularProgress color="secondary" {...rest} />
      </Box>
    </div>
  );
};

export default Loading;
