import React, { useRef, useState } from 'react';
import type { FC } from 'react';

import {
  Badge,
  Box,
  IconButton,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Bell as BellIcon } from 'react-feather';

import { useStyles } from './Notifications.style';

const Notifications: FC = () => {
  const classes = useStyles();
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <Badge
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
        >
          <IconButton
            className={classes.iconButton}
            ref={ref}
            onClick={handleOpen}
          >
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            Notifications
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default Notifications;
