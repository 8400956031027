import { Dictionary, groupBy } from 'lodash';

import {
  CheckForTimerInDescriptionType,
  CheckForTimerType,
  GetStepTimerActionOutput,
  GetStepTimerActionProps,
  GetStepTimerActionsProps,
  GetStepTitleType,
  GetTimerListType,
  HighlightInTextTimerType,
} from './types';

const allTimeFormats = ['hr', 'hours', 'min', 'minutes', 'sec', 'seconds'];

export const checkForTimer: CheckForTimerType = ({ value }) =>
  allTimeFormats.some((e: string) => value.includes(e));

const checkForTimerInDescription: CheckForTimerInDescriptionType = ({
  description,
}) => {
  if (description.includes('*')) {
    const splitValue = description.split('*');

    if (splitValue.length % 3 === 0) {
      return splitValue.some(
        (value: string, index: number) =>
          index % 2 === 0 && checkForTimer({ value }),
      );
    }
  }
  return false;
};

export const highlightInTextTimer: HighlightInTextTimerType = ({
  description,
  classname,
}) => {
  const splitValue = description?.split('*');
  if (Array.isArray(splitValue)) {
    const timerHighlightedValue = splitValue.reduce((acc, value, index) => {
      if (index % 2 !== 0 && checkForTimer({ value }))
        return `${acc}<span class=${classname}>${value}</span>&nbsp;`;
      return acc + value;
    }, '');

    return timerHighlightedValue;
  }
  return description;
};

export const getStepTitle: GetStepTitleType = ({
  stepNumber,
  description,
  classname,
}) =>
  `Step #${stepNumber}: ${
    classname ? highlightInTextTimer({ description, classname }) : description
  }`;

const getStepTimerAction = ({
  timeInSeconds,
  index,
  timerIndex,
}: GetStepTimerActionProps): GetStepTimerActionOutput => ({
  command: 'execute_timer',
  properties: {
    timer_nr: timeInSeconds,
    index,
    inTextTimerIndex: timerIndex,
  },
});

const timerFormats = {
  hourFormat: ['hr', 'hrs', 'hour', 'hours'],
  minuteFormat: ['min', 'mins', 'minute', 'minutes'],
  secondFormat: ['sec', 'secs', 'second', 'seconds'],
};

const getTimerList: GetTimerListType = ({ description }) => {
  const hasTimer = checkForTimerInDescription({ description });
  const timerList = [];
  if (hasTimer) {
    description.split('*').forEach((value, index) => {
      if (index !== 0 && index % 2 !== 0) {
        let timeInSeconds = 0;
        let trimmedValue = value.trim();

        const currentFormat = {
          hourFormat: null,
          minuteFormat: null,
          secondFormat: null,
        };

        Object.keys(timerFormats).forEach((eachFormat) => {
          timerFormats[eachFormat].some((item) => {
            if (trimmedValue.includes(item)) {
              currentFormat[eachFormat] = item;
              return true;
            }
            return false;
          });
        });

        if (currentFormat.hourFormat) {
          const valueSplitByHour = trimmedValue.split(currentFormat.hourFormat);
          const hours = valueSplitByHour?.[0]?.trim();
          timeInSeconds += hours ? parseInt(hours) * 60 * 60 : 0;
          trimmedValue = valueSplitByHour?.[1]?.trim();
        }
        if (currentFormat.minuteFormat) {
          const valueSplitByMinute = trimmedValue.split(
            currentFormat.minuteFormat,
          );
          const minutes = valueSplitByMinute?.[0]?.trim();
          timeInSeconds += minutes ? parseInt(minutes) * 60 : 0;
          trimmedValue = valueSplitByMinute?.[1]?.trim();
        }
        if (currentFormat.secondFormat) {
          const valueSplitBySecond = trimmedValue.split(
            currentFormat.secondFormat,
          );
          const seconds = valueSplitBySecond?.[0]?.trim();
          timeInSeconds += seconds ? parseInt(seconds) : 0;
        }
        timeInSeconds && timerList.push(timeInSeconds);
      }
    });
  }
  return {
    hasTimer,
    timerList,
  };
};

export const getStepTimerActions: GetStepTimerActionsProps = ({
  description,
  stepNumber,
}) => {
  const { hasTimer, timerList } = getTimerList({ description });
  const timerActions = [];
  if (hasTimer && stepNumber) {
    timerList.forEach((timeInSeconds, index) => {
      const timerAction = getStepTimerAction({
        timeInSeconds,
        index: stepNumber - 1,
        timerIndex: index,
      });
      timeInSeconds && timerAction && timerActions.push(timerAction);
    });
  }

  return { hasTimer, timerActions };
};

interface GroupIndexedItemsProps<T> {
  orderedItems: T[];
  parentKey: string | ((value: T) => string);
  childKey: string | ((value: T) => string);
}

type GroupIndexedItemsType = <T>(
  props: GroupIndexedItemsProps<T>,
) => Dictionary<Dictionary<T>>;

export const groupIndexedItems: GroupIndexedItemsType = ({
  orderedItems,
  parentKey,
  childKey,
}) => {
  return orderedItems.reduce((result, currentValue) => {
    const parentId =
      typeof parentKey === 'function' ? parentKey(currentValue) : parentKey;
    const childId =
      typeof childKey === 'function' ? childKey(currentValue) : childKey;

    result[currentValue[parentId]] = {
      ...(result[currentValue[parentId]] || {}),
      [currentValue[childId]]: currentValue,
    };
    return result;
  }, {});
};
