import React from 'react';
import type { FC, ReactNode } from 'react';

import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { LOGIN_ROUTE } from 'src/constants/routes';
import useAmplify from 'src/hooks/useAmplify';

import useAuth from '../hooks/useAuth';
import Loading from './Loading';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  useAmplify(); // initiate amplify

  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <Loading />;
  }
  if (isInitialized && !isAuthenticated) {
    return <Redirect to={{ pathname: LOGIN_ROUTE }} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
