/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import type { FC } from 'react';

import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';

import Logo from 'src/components/Logo';
import { COGNITO_GROUPS_MAP, sections } from 'src/constants/global';
import { RECIPE_VIEW_ROUTE } from 'src/constants/routes';
import useAuth from 'src/hooks/useAuth';
import { Item, NavBarProps } from 'src/types/global';
import { getConcatString } from 'src/utils';

import { useStyles } from './index.style';
import NavItem from './NavItem';

// TODO: Nested function call need to fix this
function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          // eslint-disable-line @typescript-eslint/no-use-before-define
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />,
    );
  }

  return acc;
}

function renderNavItems({
  items,
  pathname,
  depth = 0,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        [],
      )}
    </List>
  );
}

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const {
    user: { familyName, givenName, avatar = '', cognitoGroups },
  } = useAuth();

  const userName = useMemo(
    () => getConcatString([givenName, familyName]),
    [familyName, givenName],
  );

  const roles = useMemo(() => {
    const validGroupsList = Object.keys(COGNITO_GROUPS_MAP).map(
      (item) => cognitoGroups?.includes?.(item) && COGNITO_GROUPS_MAP[item],
    );
    return getConcatString(validGroupsList);
  }, [cognitoGroups]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to={RECIPE_VIEW_ROUTE}>
              <Avatar alt="User" className={classes.avatar} src={avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to={RECIPE_VIEW_ROUTE}
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {userName}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {roles}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
