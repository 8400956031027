export const darkColors = {
  primary: '#e6f6ef',
  secondary: '#33f292',
  background: '#1a1d1b',
  elevatedBackground: '#262a28',
  text3: '#222624',
  textPrimary: '#eff9f4',
  textSecondary: '#a2aba6',
  error: '#dc1e34',
  warning: '#fe7414',
  success: '#008742',
  information: '#0079c3',
  chipsBackground: '#434845',
  chipsText: '#767b79',
  drafts: '#6267c6',
  onDev: '#c3492e',
  onBeta: '#ff8119',
  draftsBackground: '#050610',
  inProgressBackground: '#130304',
  inReviewBackground: '#010e14',
  onDevBackground: '#150706',
  onBetaBackground: '#171301',
  onProdBackground: '#0b1c12',
};

export const lightColors = {
  primary: '#c0e9d7',
  secondary: '#1a1d1b',
  background: '#f7f9f8',
  elevatedBackground: '#ffffff',
  text3: '#ffffff',
  textPrimary: '#222624',
  textSecondary: '#646d67',
  error: '#dc1e34',
  warning: '#fe7414',
  success: '#008742',
  information: '#0079c3',
  chipsBackground: '#edf2f0',
  chipsText: '#bec3c1',
  drafts: '#3a39a5',
  onDev: '#c3492e',
  onBeta: '#ff8119',
  draftsBackground: '#e9eaf7',
  inProgressBackground: '#e9eaf7',
  inReviewBackground: '#dff5fe',
  onDevBackground: '#f9ebea',
  onBetaBackground: '#fefae4',
  onProdBackground: '#e4f4ea',
};

export const colorPalette = {
  light: lightColors,
  dark: darkColors,
};
