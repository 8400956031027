import React, { FC, memo } from 'react';

import { Helmet } from 'react-helmet';

import Loading from 'src/components/Loading';

import { PageProps } from './types';

const Page: FC<PageProps> = memo(
  ({ children, title = '', loading = false, ...rest }) => (
    <div {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {loading && <Loading />}
      {children}
    </div>
  ),
);

export default Page;
