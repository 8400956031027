import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import * as settingsActions from 'src/redux/settings/thunk';
import { RootState } from 'src/redux/store';
import { Settings } from 'src/types/global';

interface SettingsOutputType {
  settings: Settings;
  saveSettings: (update: Settings) => void;
}

const useSettings = (): SettingsOutputType => {
  const dispatch = useDispatch();
  const settings = useSelector((state: RootState) => state.settings);

  const saveSettings = useCallback(
    (update: Settings) => {
      dispatch(settingsActions.saveSettings(update));
    },
    [dispatch],
  );

  return {
    settings,
    saveSettings,
  };
};

export default useSettings;
