import React from 'react';
import type { FC, ReactNode } from 'react';

import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { RECIPE_VIEW_ROUTE } from 'src/constants/routes';
import useAmplify from 'src/hooks/useAmplify';

import useAuth from '../hooks/useAuth';
import Loading from './Loading';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  useAmplify(); // initiate amplify

  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <Loading />;
  }
  if (isInitialized && isAuthenticated) {
    return <Redirect to={RECIPE_VIEW_ROUTE} />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
