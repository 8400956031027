import { makeStyles } from '@material-ui/core';

import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  middle: {
    position: 'fixed',
    top: '40%',
    left: 'calc(50% - 22px)',
    padding: theme.spacing(3),
    zIndex: 9999,
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: theme.spacing(6),
    paddingTop: theme.spacing(3),
  },
}));
