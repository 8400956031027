import { Star as StarIcon } from 'react-feather';

import {
  ROUTE_404,
  RECIPE_VIEW_ROUTE,
  VIDEO_VIEW_ROUTE,
  INGREDIENT_VIEW_ROUTE,
} from 'src/constants/routes';
import {
  EnvironmentType,
  Section,
  StatusType,
  StatusUtilsType,
  ToastVariant,
} from 'src/types/global';

// TODO: move all static images to /src and import dynamically
export const PLACEHOLDER_IMAGE = '/static/images/Image.svg';
export const PLACEHOLDER_VIDEO_THUMBNAIL = '/static/images/VideoThumbnail.svg';
export const PLACEHOLDER_VIDEO = '/static/images/Video.svg';
export const UPLOAD_IMAGE = '/static/images/UploadFile.svg';
export const NOT_FOUND_IMAGE = '/static/images/404.svg';
export const LOGO_IMAGE = '/static/images/Logo.png';

export const sections: Section[] = [
  {
    subheader: 'CONTENT',
    items: [
      {
        title: 'Recipe Management',
        icon: StarIcon,
        href: '/',
        items: [
          {
            title: 'Recipes',
            href: RECIPE_VIEW_ROUTE,
          },
          {
            title: 'Videos',
            href: VIDEO_VIEW_ROUTE,
          },
          {
            title: 'Ingredients',
            href: INGREDIENT_VIEW_ROUTE,
          },
        ],
      },
    ],
  },
  {
    subheader: 'CALCULATOR',
    items: [
      {
        title: 'Coming Soon',
        href: ROUTE_404,
        icon: StarIcon,
        items: [
          {
            title: 'Item1',
            href: ROUTE_404,
          },
        ],
      },
    ],
  },
];

export const TOAST_VARIANT: ToastVariant = {
  ERROR: {
    variant: 'error',
  },
  SUCCESS: {
    variant: 'success',
  },
};

export const STATUS: { [key: string]: StatusType } = {
  DRAFT: 'draft',
  IN_PROGRESS: 'in progress',
  IN_REVIEW: 'in review',
  APPROVED: 'approved',
};

export const ENVIRONMENT: { [key: string]: EnvironmentType } = {
  DEV: 'dev',
  BETA: 'beta',
  PROD: 'prod',
};

export const FILTER_TAB_LABEL = {
  ALL: 'all',
  DRAFT: 'my drafts',
  IN_PROGRESS: 'in progress',
  IN_REVIEW: 'in review',
  ON_DEV: 'approved/on dev',
  ON_BETA: 'on beta',
  ON_PROD: 'on prod',
};

export const STATUS_UTILS: StatusUtilsType = {
  [STATUS.DRAFT]: { COLOR: 'myDrafts', LABEL: 'MY DRAFTS' },
  [STATUS.IN_PROGRESS]: { COLOR: 'inProgress', LABEL: 'IN PROGRESS' },
  [STATUS.IN_REVIEW]: { COLOR: 'inReview', LABEL: 'IN REVIEW' },
  [ENVIRONMENT.DEV]: { COLOR: 'onDev', LABEL: 'ON DEV' },
  [ENVIRONMENT.BETA]: { COLOR: 'onBeta', LABEL: 'ON BETA' },
  [ENVIRONMENT.PROD]: { COLOR: 'onProd', LABEL: 'ON PROD' },
};
export const STEP_CONDITION = {
  LINKED_DEVICE: {
    command: 'linked_device',
    rules: { compare_mode: 'or', linked: 0 },
    error_message: { code: 'no_device_linked', properties: { duration: 0 } },
  },
  DEVICE: {
    command: 'e_device',
    rules: { compare_mode: 'or', session_id_mismatch: 1, is_wifi_connected: 0 },
    error_message: {
      code: 'device_not_available',
      properties: { duration: 0 },
    },
  },
  TIMER: {
    command: 'e_timer',
    rules: { compare_mode: 'or', index: -999, timerFinished: 1 },
    error_message: {
      code: 'timer_running',
      properties: {
        duration: 0,
        buttons: [
          {
            button_text: 'Force complete the step',
            actions: [
              {
                command: 'force_stop_timer',
                properties: { index: -999 },
              },
            ],
          },
        ],
      },
    },
  },
};

export const COGNITO_GROUPS = {
  GUEST_USER: process.env.REACT_APP_AWS_COGNITO_GROUP_GUEST_USER,
  APP_USER: process.env.REACT_APP_AWS_COGNITO_GROUP_APP_USER,
  CULINARY_TEAM: process.env.REACT_APP_AWS_COGNITO_GROUP_CULINARY_TEAM,
  CULINARY_ADMIN: process.env.REACT_APP_AWS_COGNITO_GROUP_CULINARY_ADMIN,
  VIDEO_TEAM: process.env.REACT_APP_AWS_COGNITO_GROUP_VIDEO_TEAM,
  VIDEO_ADMIN: process.env.REACT_APP_AWS_COGNITO_GROUP_VIDEO_ADMIN,
  APP_ADMIN: process.env.REACT_APP_AWS_COGNITO_GROUP_APP_ADMIN,
};

export const COGNITO_GROUPS_MAP = {
  [COGNITO_GROUPS.GUEST_USER]: 'Guest User',
  [COGNITO_GROUPS.APP_USER]: 'App User',
  [COGNITO_GROUPS.VIDEO_TEAM]: 'Video Team',
  [COGNITO_GROUPS.VIDEO_ADMIN]: 'Video Admin',
  [COGNITO_GROUPS.CULINARY_TEAM]: 'Culinary Team',
  [COGNITO_GROUPS.CULINARY_ADMIN]: 'Culinary Admin',
  [COGNITO_GROUPS.APP_ADMIN]: 'App Admin',
};

export const ASSETS_BUCKET_ACL = 'bucket-owner-full-control';

export const STEP_BUTTON_TEXT = 'MARK COMPLETE';

export const REQUIRED_FIELDS = {
  RECIPE: {
    DRAFT: ['recipeName', 'skillLevel'],
  },
};

export const CONTENT_TYPES = {
  RECIPE: 'RECIPE',
  INGREDIENT: 'INGREDIENT',
  VIDEO: 'VIDEO',
};
