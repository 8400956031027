export const ROUTE_404 = '/404';
export const LOGIN_ROUTE = '/login';

export const RECIPE_VIEW_ROUTE = '/recipe-management/recipes';

export const getRecipeEditRoute = (id: string): string =>
  `/recipe-management/recipes/${id}/edit`;
export const RECIPE_EDIT_ROUTE = getRecipeEditRoute(':recipeId');

export const INGREDIENT_VIEW_ROUTE = '/recipe-management/ingredients';

export const getIngredientEditRoute = (id: string): string =>
  `/recipe-management/ingredients/${id}/edit`;

export const INGREDIENT_EDIT_ROUTE = getIngredientEditRoute(':ingredientId');

export const VIDEO_VIEW_ROUTE = '/recipe-management/videos';

export const getVideoEditRoute = (id: string): string =>
  `/recipe-management/videos/${id}/edit`;

export const VIDEO_EDIT_ROUTE = getVideoEditRoute(':videoId');
