import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import { Router } from 'react-router-dom';

import devClient from 'src/apollo/devClient';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import routes, { renderRoutes } from 'src/routes';
import { createTheme } from 'src/theme';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: React.FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ApolloProvider client={devClient}>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <SnackbarProvider dense maxSnack={3}>
            <Router history={history}>
              <GlobalStyles />
              <ScrollReset />
              {renderRoutes(routes)}
            </Router>
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
