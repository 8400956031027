import React, { useCallback } from 'react';
import type { FC } from 'react';

import { Box, Container, Button, Typography } from '@material-ui/core';

import Page from 'src/components/Page';
import useAmplify from 'src/hooks/useAmplify';

import { useStyles } from './Login.style';

const LoginView: FC = () => {
  const classes = useStyles();
  const { signIn } = useAmplify();

  const handleSignIn = useCallback(() => {
    signIn();
  }, [signIn]);

  return (
    <Page className={classes.root} title="Login">
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box className={classes.card}>
            <Box mt="47px" ml="47px">
              <Typography variant="h3" className={classes.signInText}>
                CHEF iQ® Console
              </Typography>
            </Box>
            <Box mx="47px" my="30px">
              <Button
                onClick={handleSignIn}
                variant="contained"
                className={classes.button}
              >
                SIGN IN
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default LoginView;
