import { AwsConfig, ApolloConfig, StorageConfig } from './types/awsConfig';

const hostURL = `${window.location.origin}/` || 'http://localhost:3000/';

const {
  REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_AWS_COGNITO_REGION,
  REACT_APP_AWS_COGNITO_USER_POOL_ID,
  REACT_APP_AWS_COGNITO_APP_CLIENT,
  REACT_APP_AWS_COGNITO_DOMAIN,
  REACT_APP_AWS_APPSYNC_DEV_GRAPHQL_ENDPOINT,
  REACT_APP_AWS_APPSYNC_BETA_GRAPHQL_ENDPOINT,
  REACT_APP_AWS_APPSYNC_PROD_GRAPHQL_ENDPOINT,
  REACT_APP_AZURE_PROVIDER_NAME,
  REACT_APP_AWS_STORAGE_BUCKET,
} = process.env;

const awsConfig: AwsConfig = {
  Auth: {
    identityPoolId: REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    region: REACT_APP_AWS_COGNITO_REGION,
    userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_COGNITO_APP_CLIENT,
    oauth: {
      domain: REACT_APP_AWS_COGNITO_DOMAIN,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: hostURL,
      redirectSignOut: hostURL,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    customProvider: REACT_APP_AZURE_PROVIDER_NAME,
  },
  Storage: {
    AWSS3: {
      bucket: REACT_APP_AWS_STORAGE_BUCKET,
      region: REACT_APP_AWS_COGNITO_REGION,
    },
  },
};

export const apolloConfig: ApolloConfig = {
  devGraphqlEndpoint: REACT_APP_AWS_APPSYNC_DEV_GRAPHQL_ENDPOINT,
  betaGraphqlEndpoint: REACT_APP_AWS_APPSYNC_BETA_GRAPHQL_ENDPOINT,
  prodGraphqlEndpoint: REACT_APP_AWS_APPSYNC_PROD_GRAPHQL_ENDPOINT,
};

export const storageConfig: StorageConfig = {
  customPrefix: {
    public: '',
  },
};

export default awsConfig;
