import { makeStyles } from '@material-ui/core';

import { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 320,
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  iconButton: {
    color: theme.palette.text.primary,
  },
}));
