import { intervalToDuration, addSeconds, format as formatDate } from 'date-fns';

import {
  ENVIRONMENT,
  FILTER_TAB_LABEL,
  STATUS,
  STATUS_UTILS,
} from 'src/constants/global';

import {
  GetConcatStringInput,
  GetEnvironmentInput,
  GetStatusConfigType,
  NameUtilInput,
  ResizeImageProps,
  ViewCardTitleInput,
} from './types';

export const convertSecondsToTime = (seconds: number) => {
  const tempDate = new Date(0);
  const durationIndex = intervalToDuration({
    start: tempDate,
    end: addSeconds(tempDate, seconds),
  });

  return durationIndex;
};

export const convertTimeToSeconds = (duration: Duration) => {
  let totalSeconds = 0;

  totalSeconds += duration.hours ? duration.hours * 60 * 60 : 0;
  totalSeconds += duration.minutes ? duration.minutes * 60 : 0;
  totalSeconds += duration.seconds ? duration.seconds : 0;

  return totalSeconds;
};

export const formatDuration = (time: number) => {
  const durationIndex = convertSecondsToTime(time);

  let formattedDuration = '';

  formattedDuration += durationIndex.hours ? `${durationIndex.hours} hr ` : '';
  formattedDuration += durationIndex.minutes
    ? `${durationIndex.minutes} min`
    : '';

  return formattedDuration;
};

export const formatTimestamp = (timestamp: string) =>
  timestamp && formatDate(new Date(timestamp), 'MM/dd/yyyy HH:mm');

export const getStatusConfig: GetStatusConfigType = ({ status, env }) => {
  const statusUtilsKey = status === STATUS.APPROVED ? env : status;
  const statusConfig = STATUS_UTILS[statusUtilsKey];

  return { label: statusConfig?.LABEL || '', color: statusConfig?.COLOR };
};

export const getEnvironment = ({ selectedTab }: GetEnvironmentInput) => {
  if (selectedTab === FILTER_TAB_LABEL.ON_BETA) return ENVIRONMENT.BETA;
  if (selectedTab === FILTER_TAB_LABEL.ON_PROD) return ENVIRONMENT.PROD;

  return ENVIRONMENT.DEV;
};

export const getAvatarName = ({ userName }: NameUtilInput) => {
  const [firstName, lastName] = userName.split(' ');

  if (!firstName || !lastName) return '';

  if (firstName && lastName)
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;

  return firstName.charAt(0);
};

export const getRandomColor = ({ userName }: NameUtilInput) => {
  let hash = 0;
  if (userName.length === 0) return 'grey';
  for (let i = 0; i < userName.length; i++) {
    hash = userName.charCodeAt(i) + ((hash << 3) - hash);
    hash &= hash;
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
};

export const getConcatString = (stringList: GetConcatStringInput) =>
  Array.isArray(stringList) ? stringList.filter((e) => e).join(' ') : '';

export const getViewCardTitle = ({
  recipeNumber,
  name,
}: ViewCardTitleInput) => {
  const title = recipeNumber ? `#${recipeNumber}. ${name}` : name;
  return title;
};

const getCloudFrontUrl = (bucketName: string) => {
  if (bucketName === 'icons') return 'd2ssxs0pm6cdlf';
  if (bucketName === 'images') return 'd2boay4pj6b2l0';
  if (bucketName === 'howtos') return 'd38z0n8bh9c895';
  if (bucketName === 'recipes') return 'd1y06f4dn4rjc0';
  return '';
};

const getLastItem = (path: string) => {
  return path.substring(path.lastIndexOf('/') + 1);
};

export const newResizedImageUrl = ({
  imageUrl,
  // size = IS_TABLET ? '1024' : '512',
  size = '256',
}: ResizeImageProps) => {
  const fileName = getLastItem(imageUrl);
  const domainPath = imageUrl.replace(fileName, '');
  const resizeUrl = `${domainPath}${size}/${fileName}`;
  return resizeUrl;
};

export const resizedImageUrl = ({
  imageUrl,
  size = '256',
  format,
}: ResizeImageProps) => {
  if (imageUrl) {
    console.log(imageUrl);
    const newCloudFrontUrl = [
      'https://assets.chefiq.com',
      'https://assets.dev.chefiq.com',
      'https://assets.sandbox.chefiq.com',
    ];

    const hasNewLink = newCloudFrontUrl.some((url) => imageUrl.includes(url));
    if (hasNewLink) {
      return newResizedImageUrl({ imageUrl, size });
    }
    const s3WebUrl = '.s3.amazonaws.com';
    const cloudfrontUrl = '.chefiq.com';
    const hascloudFrontUrl = imageUrl.includes(cloudfrontUrl);
    let bucket;
    let resizeUrl;

    if (hascloudFrontUrl) {
      const arr = imageUrl.split(cloudfrontUrl);
      const splitedUrl = arr[0] || '';
      const path = arr[1] || '';
      const index = splitedUrl.lastIndexOf('/');
      const bucketName = splitedUrl.substring(index + 1);
      const cloudfront = getCloudFrontUrl(bucketName);
      resizeUrl = `https://${cloudfront}.cloudfront.net${path}`;
      const queryStringParam = path.includes('?')
        ? `&bucket=${bucketName}&cloudfront=${cloudfront}`
        : `?chefiq&bucket=${bucketName}&cloudfront=${cloudfront}`;
      const lastIndex = resizeUrl.lastIndexOf('/');
      resizeUrl = `${resizeUrl.slice(0, lastIndex)}/${size}${resizeUrl.slice(
        lastIndex,
      )}${queryStringParam}`;
      if (format) resizeUrl += `&format=${format}`;
      return resizeUrl;
    }
    const arr = imageUrl.split('chefiq-');
    const splitedUrl = arr[1] || '';
    const splitedUrlArr = splitedUrl.split('/');
    const bucketName = splitedUrlArr[0] || '';

    const hasS3WebUrl = bucketName.includes(s3WebUrl);
    if (hasS3WebUrl) {
      bucket = bucketName.replace(s3WebUrl, '');
    } else {
      bucket = bucketName;
    }
    const cloudfront = getCloudFrontUrl(bucket);
    const pathArr = imageUrl.split(`chefiq-${bucketName}`);
    const path = pathArr[1] || '';
    resizeUrl = `https://${cloudfront}.cloudfront.net${path}`;
    const queryStringParam = path.includes('?')
      ? `&bucket=${bucket}&cloudfront=${cloudfront}`
      : `?chefiq&bucket=${bucket}&cloudfront=${cloudfront}`;
    const lastIndex = resizeUrl.lastIndexOf('/');
    resizeUrl = `${resizeUrl.slice(0, lastIndex)}/${size}${resizeUrl.slice(
      lastIndex,
    )}${queryStringParam}`;
    if (format) resizeUrl += `&format=${format}`;
    return resizeUrl;
  }
  return '';
};
