import React from 'react';
import type { FC } from 'react';

import { LOGO_IMAGE } from 'src/constants/global';
import { LogoProps } from 'src/types/global';

const Logo: FC<LogoProps> = (props) => (
  <img alt="Logo" src={LOGO_IMAGE} {...props} />
);

export default Logo;
