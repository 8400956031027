import { makeStyles } from '@material-ui/core';

import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundImage:
      'url(https://chefman.com/wp-content/uploads/2019/05/banner1v4-2.jpg)',

    backgroundSize: 'auto 100vh',
  },
  signInText: {
    height: 28,
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.17,
    letterSpacing: -0.06,
    color: theme.palette.text.primary,
  },
  card: {
    position: 'absolute',
    top: '20%',
    width: 517,
    height: 244,
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    opacity: '0.95',
  },
  button: {
    width: 423,
    height: 42,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));
