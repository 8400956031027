import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import type { Theme as MuiTheme } from '@material-ui/core/styles/createMuiTheme';
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground,
} from '@material-ui/core/styles/createPalette';
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import { merge as _merge } from 'lodash';

import { colorPalette } from './colors';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

interface TypeBackground extends MuiTypeBackground {
  dark: string;
}

interface BadgeStatusColor {
  color: string;
  background: string;
}

interface Badge {
  [index: string]: BadgeStatusColor;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
  badge: Badge;
}

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
interface ThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  palette?: Record<string, any>;
  shadows?: MuiShadows;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colorPalette.light.elevatedBackground,
        dark: colorPalette.light.background,
        paper: colorPalette.light.elevatedBackground,
      },
      primary: {
        main: colorPalette.light.primary,
      },
      secondary: {
        main: colorPalette.light.secondary,
      },
      error: {
        main: colorPalette.light.error,
      },
      warning: {
        main: colorPalette.light.warning,
      },
      success: {
        main: colorPalette.light.success,
      },
      text: {
        primary: colorPalette.light.textPrimary,
        secondary: colorPalette.light.textSecondary,
        hint: colorPalette.light.text3,
      },
      badge: {
        myDrafts: {
          color: colorPalette.light.drafts,
          background: colorPalette.light.draftsBackground,
        },
        inProgress: {
          color: colorPalette.light.error,
          background: colorPalette.light.inProgressBackground,
        },
        inReview: {
          color: colorPalette.light.information,
          background: colorPalette.light.inReviewBackground,
        },
        onDev: {
          color: colorPalette.light.onDev,
          background: colorPalette.light.onDevBackground,
        },
        onBeta: {
          color: colorPalette.light.onBeta,
          background: colorPalette.light.onBetaBackground,
        },
        onProd: {
          color: colorPalette.light.success,
          background: colorPalette.light.onProdBackground,
        },
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: colorPalette.dark.elevatedBackground,
        dark: colorPalette.dark.background,
        paper: colorPalette.dark.elevatedBackground,
      },
      primary: {
        main: colorPalette.dark.primary,
      },
      secondary: {
        main: colorPalette.dark.secondary,
      },
      error: {
        main: colorPalette.light.error,
      },
      warning: {
        main: colorPalette.light.warning,
      },
      success: {
        main: colorPalette.light.success,
      },
      text: {
        primary: colorPalette.dark.textPrimary,
        secondary: colorPalette.dark.textSecondary,
        hint: colorPalette.dark.text3,
      },
      badge: {
        myDrafts: {
          color: colorPalette.dark.drafts,
          background: colorPalette.dark.draftsBackground,
        },
        inProgress: {
          color: colorPalette.dark.error,
          background: colorPalette.dark.inProgressBackground,
        },
        inReview: {
          color: colorPalette.dark.information,
          background: colorPalette.dark.inReviewBackground,
        },
        onDev: {
          color: colorPalette.dark.onDev,
          background: colorPalette.dark.onDevBackground,
        },
        onBeta: {
          color: colorPalette.dark.onBeta,
          background: colorPalette.dark.onBetaBackground,
        },
        onProd: {
          color: colorPalette.dark.success,
          background: colorPalette.dark.onProdBackground,
        },
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _merge({}, baseOptions, themeOptions, { direction: config.direction }),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
};
