import React, { Suspense, Fragment, lazy } from 'react';

import { Switch, Redirect, Route } from 'react-router-dom';

import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import Loading from 'src/components/Loading';
import {
  ROUTE_404,
  LOGIN_ROUTE,
  RECIPE_VIEW_ROUTE,
  RECIPE_EDIT_ROUTE,
  INGREDIENT_VIEW_ROUTE,
  INGREDIENT_EDIT_ROUTE,
  VIDEO_VIEW_ROUTE,
  VIDEO_EDIT_ROUTE,
} from 'src/constants/routes';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoginView from 'src/views/Login';

import { Routes } from './types/global';

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<Loading />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i} // eslint-disable-line react/no-array-index-key
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: LOGIN_ROUTE,
    component: LoginView,
    guard: GuestGuard,
  },
  {
    exact: true,
    path: ROUTE_404,
    component: lazy(() => import('src/views/Errors/NotFoundView')),
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to={RECIPE_VIEW_ROUTE} />,
      },
      {
        exact: true,
        path: RECIPE_EDIT_ROUTE,
        component: lazy(() => import('src/views/Recipe/EditRecipe')),
      },
      {
        exact: true,
        path: RECIPE_VIEW_ROUTE,
        component: lazy(() => import('src/views/Recipe/RecipeView')),
      },
      {
        exact: true,
        path: INGREDIENT_VIEW_ROUTE,
        component: lazy(() => import('src/views/Ingredient/IngredientView')),
      },
      {
        exact: true,
        path: INGREDIENT_EDIT_ROUTE,
        component: lazy(() => import('src/views/Ingredient/EditIngredient')),
      },
      {
        exact: true,
        path: VIDEO_VIEW_ROUTE,
        component: lazy(() => import('src/views/Video/VideoView')),
      },
      {
        exact: true,
        path: VIDEO_EDIT_ROUTE,
        component: lazy(() => import('src/views/Video/EditVideo')),
      },
      {
        component: () => <Redirect to={ROUTE_404} />,
      },
    ],
  },
];

export default routes;
