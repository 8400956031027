import { ReactElement } from 'react';

import useScrollReset from 'src/hooks/useScrollReset';

const ScrollReset = (): ReactElement => {
  useScrollReset();

  return null;
};

export default ScrollReset;
